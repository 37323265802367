import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { Grid } from '@mui/material';
import { Button, DatePicker, Text, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadXlsx } from 'helpers/downloadXLSX';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services';

const typeFiler = [
  { label: 'Por OS', value: '0' },
  { label: 'Data de Operação', value: '1' },
  { label: 'Data do Laudo', value: '2' },
  { label: 'Data da Nota', value: '3' },
];

export const LaunchParts = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await providerService.getLaunchParts({
        input_filter: values.input_filter,
        input_os: values.input_os,
        input_data: values.input_data,
        output_data: values.output_data,
      });
      if (data.length > 0) {
        downloadXlsx('ingenico_lancamento_pecas', data);
        toast.success('Relatório gerado com sucesso');
      } else {
        toast.error('Não há dados para esse relatório.');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Lançamento Peça em OS</PageTitle>

      <Formik
        initialValues={{
          input_filter: '',
          input_os: '',
          input_data: new Date(),
          output_data: new Date(),
        }}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} lg={3}>
                <Field
                  size='small'
                  loading={isLoading}
                  name='input_filter'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Filtro'
                  options={typeFiler}
                  component={Select}
                />
              </Grid>
              {values.input_filter === '0' && (
                <>
                  <Grid item xs={12} md={3} lg={3}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_os'
                      variant='outlined'
                      fullWidth
                      inputVariant='outlined'
                      placeholder='Informe a OS'
                      label='OS'
                      component={Text}
                    />
                  </Grid>
                </>
              )}
              {(values.input_filter === '1' ||
                values.input_filter === '2' ||
                values.input_filter === '3') && (
                <>
                  <Grid item xs={12} lg={2}>
                    <Field
                      size='small'
                      disabled={isLoading}
                      name='input_data'
                      variant='inline'
                      fullWidth
                      inputVariant='outlined'
                      label='Data inicial'
                      component={DatePicker}
                    />
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <Field
                      size='small'
                      disabled={isLoading}
                      name='output_data'
                      variant='inline'
                      fullWidth
                      inputVariant='outlined'
                      label='Data final'
                      component={DatePicker}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={3} lg={3}>
                <Button fullWidth type='submit' loading={isLoading}>
                  Exportar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
