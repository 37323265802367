import React, { useRef } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Dialog } from 'components';
import { downloadcsv } from 'helpers/downloadcsv';
import { downloadXlsx } from 'helpers/downloadXLSX';
import { readCsv } from 'helpers/readCSV';
import { conferenceService } from 'services';

const model = [
  {
    MODELO: 'xxxxxxx',
    PN: 'xxxxxxx',
    SERIAL: 'xxxxxxx',
    ATIVO: 'xxxxxxx',
    NF: 'xxxxxxx',
    CLIENTE: 'xxxxxxx',
  },
  {
    MODELO: 'xxxxxxx',
    PN: 'xxxxxxx',
    SERIAL: 'xxxxxxx',
    ATIVO: 'xxxxxxx',
    NF: 'xxxxxxx',
    CLIENTE: 'xxxxxxx',
  },
  {
    MODELO: 'xxxxxxx',
    PN: 'xxxxxxx',
    SERIAL: 'xxxxxxx',
    ATIVO: 'xxxxxxx',
    NF: 'xxxxxxx',
    CLIENTE: 'xxxxxxx',
  },
  {
    MODELO: 'xxxxxxx',
    PN: 'xxxxxxx',
    SERIAL: 'xxxxxxx',
    ATIVO: 'xxxxxxx',
    NF: 'xxxxxxx',
    CLIENTE: 'xxxxxxx',
  },
];

export const AutomaticConference = ({
  isModalOpenAutomaticConference,
  handleCloseModalAutomaticConference,
  user,
}) => {
  const fileInputRef = useRef(null);

  const handleFileInputChange = async (event) => {
    try {
      const data_csv = await readCsv(event);
      console.log(data_csv);
      const { data } = await conferenceService.automaticConference({
        dataCSV: data_csv,
        user: user,
      });
      if (data.status === 200) {
        toast.success('Conferência automatica feita com sucesso.');
        handleCloseModalAutomaticConference();
      } else {
        toast.error('erro ao fazer conferência.');
        downloadcsv('Lista_erro_conferencia_automatica', data.listError);
      }
    } catch (error) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
  };

  const handleDownloadModel = () => {
    downloadXlsx('modeloConferencia', model);
  };

  const handleUploadFileClick = () => {
    fileInputRef.current.value = ''; // Limpa a seleção anterior
    fileInputRef.current.click(); // Abre o seletor de arquivos
  };

  return (
    <Dialog
      open={isModalOpenAutomaticConference}
      onClose={handleCloseModalAutomaticConference}
      title='Conferência Automática'
      maxWidth={'md'}
    >
      <Dialog.Title onClose={handleCloseModalAutomaticConference}>
        Conferência automática
      </Dialog.Title>
      <Dialog.Content>
        <input
          type='file'
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileInputChange}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={4}>
            <Button fullWidth color='secondary' onClick={handleUploadFileClick}>
              Importar CSV
            </Button>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Button fullWidth onClick={handleDownloadModel}>
              download modelo
            </Button>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Button fullWidth onClick={handleCloseModalAutomaticConference}>
              sair
            </Button>
          </Grid>
        </Grid>
      </Dialog.Content>
    </Dialog>
  );
};
