import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadcsv } from 'helpers/downloadcsv';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services/providers';

export const ProductionDepartmentReport = () => {
  const [isLoading, setIsLoading] = useState(0);
  const [etapa, setEtapa] = useState([]);

  const handleExportReport = async (values) => {
    toast.info('Aguarde, relatório sendo gerado.');
    setIsLoading(1);

    const formattedInputDate = values.input_data
      .toLocaleDateString()
      .replace('/', '-')
      .replace('/', '-');
    const formattedOutputDate = values.output_data
      .toLocaleDateString()
      .replace('/', '-')
      .replace('/', '-');

    try {
      const result = await providerService.getProductionDepartmentReport(
        formattedInputDate,
        formattedOutputDate,
        values.type_data,
        values.etapa,
      );

      downloadcsv('ingenico_producao_por_setor', result.data);
      toast.success('Relatório gerado com sucesso');
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    } finally {
      setIsLoading(0);
    }
  };

  const selectRangeOptions = [
    {
      value: '0',
      label: 'Resumido',
    },
    {
      value: '1',
      label: 'Detalhado',
    },
  ];

  const getEtapas = useCallback(async () => {
    const data = await providerService.getEtapas();
    setEtapa(data);
  }, []);

  const handleCreateTable = (values) => {
    console.log(values);
  };

  useEffect(() => {
    getEtapas();
  }, [getEtapas]);

  return (
    <>
      <PageTitle>Relatório Produção por Setor</PageTitle>
      <Formik
        initialValues={{
          input_data: new Date(),
          output_data: new Date(),
          type_data: '0',
          etapa: 0,
        }}
        onSubmit={handleExportReport}
      >
        {({ values }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2} lg={2}>
                <Field
                  size='small'
                  name='input_data'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Data inicial'
                  component={DatePicker}
                  disabled={isLoading == 1 ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <Field
                  size='small'
                  name='output_data'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Data final'
                  component={DatePicker}
                  disabled={isLoading == 1 ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <Field
                  size='small'
                  name='type_data'
                  label='Tipo'
                  variant='outlined'
                  options={selectRangeOptions}
                  component={Select}
                  disabled={isLoading == 1 ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <Field
                  size='small'
                  name='etapa'
                  label='Etapa'
                  variant='outlined'
                  options={etapa}
                  component={Select}
                  disabled={isLoading == 1 ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <Button
                  fullWidth
                  type='submit'
                  disabled={isLoading == 1 ? true : false}
                  loading={isLoading == 1 ? true : false}
                >
                  Exportar relatório
                </Button>
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <Button
                  fullWidth
                  onClick={() => handleCreateTable(values)}
                  disabled={isLoading == 1 ? true : false}
                  loading={isLoading == 1 ? true : false}
                >
                  Relatório
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
