import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Button, Spacer, Table, Text, Dialog } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadcsv } from 'helpers/downloadcsv';
import { downloadXlsx } from 'helpers/downloadXLSX';
import { readCsv } from 'helpers/readCSV';
import { AuthContext } from 'hooks/useAuth';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { serviceOrderService } from 'services';
import { useContextSelector } from 'use-context-selector';

const columns = [
  { label: 'NF' },
  { label: 'Data Agendado' },
  { label: 'Próxima abertura' },
  { label: 'Ações' },
];

const model = [
  {
    NF: 'xxxxxxx',
    Cliente: 'xxxxxxx',
    TIPO_DE_SERVIÇO_DA_NF: 'xxxxxxx',
    TECNOLOGIA: 'xxxxxxx',
    qtd: 'xxxxxxx',
    DT_REC: 'xxxxxxx',
    STATUS: 'xxxxxxx',
    NF_ING: 'xxxxxxx',
    OBSERVAÇÃO: 'xxxxxxx',
  },
  {
    NF: 'xxxxxxx',
    Cliente: 'xxxxxxx',
    TIPO_DE_SERVIÇO_DA_NF: 'xxxxxxx',
    TECNOLOGIA: 'xxxxxxx',
    qtd: 'xxxxxxx',
    DT_REC: 'xxxxxxx',
    STATUS: 'xxxxxxx',
    NF_ING: 'xxxxxxx',
    OBSERVAÇÃO: 'xxxxxxx',
  },
  {
    NF: 'xxxxxxx',
    Cliente: 'xxxxxxx',
    TIPO_DE_SERVIÇO_DA_NF: 'xxxxxxx',
    TECNOLOGIA: 'xxxxxxx',
    qtd: 'xxxxxxx',
    DT_REC: 'xxxxxxx',
    STATUS: 'xxxxxxx',
    NF_ING: 'xxxxxxx',
    OBSERVAÇÃO: 'xxxxxxx',
  },
  {
    NF: 'xxxxxxx',
    Cliente: 'xxxxxxx',
    TIPO_DE_SERVIÇO_DA_NF: 'xxxxxxx',
    TECNOLOGIA: 'xxxxxxx',
    qtd: 'xxxxxxx',
    DT_REC: 'xxxxxxx',
    STATUS: 'xxxxxxx',
    NF_ING: 'xxxxxxx',
    OBSERVAÇÃO: 'xxxxxxx',
  },
];

export const ScheduleOpenServiceOrder = () => {
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [scheduleInvoices, setScheduleInvoices] = useState([]);
  const [logs, setLogs] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const fileInputRef = useRef(null);
  const [isModal, setIsModal] = useState(false);

  const user = useContextSelector(AuthContext, (context) => context.user);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => {
    setIsModal(!isModal);
  };

  const [scheduleInvoicesData, , loadingScheduleInvoices, scheduleInvoicesReload] = useQuery(
    serviceOrderService.getScheduleInvoices,
    [],
  );

  useEffect(() => {
    setScheduleInvoices(scheduleInvoicesData);
  }, [scheduleInvoicesData]);

  const handleSubmit = async () => {
    if (invoiceNumber !== '') {
      toast.info(`Enviando NF: ${invoiceNumber}`);
      try {
        setIsLoading(true);
        const res = await serviceOrderService.scheduleOpenServiceOrder(invoiceNumber, user.id);
        if (res?.data?.statusCode === 400) {
          toast.error(res.data.message);
          setLogs(`${res.data.message}`);
        } else {
          toast.success('Nota enviada com sucesso!');
        }
      } catch (err) {
        setIsModal(true);
        toast.error(err.response.data.message);
        setLogs(`${err.response.data.message}`);
      } finally {
        setIsLoading(false);
        scheduleInvoicesReload();
      }
    } else {
      toast.warning('Nenhuma NF foi digitada');
    }
  };

  const handleUploadFileClick = async () => {
    fileInputRef.current.value = '';
    fileInputRef.current.click();
  };

  const handleDownloadModel = () => {
    downloadXlsx('modelo_abertura', model);
  };

  const handleFileInputChange = async (event) => {
    setIsLoading(true);
    try {
      const data_csv = await readCsv(event);
      console.log(data_csv);
      toast.success('Arquivo lido com sucesso!');
      const { data } = await serviceOrderService.openScheduleInvoiceCsv(data_csv, user.id);
      if (data.status !== 200) {
        downloadcsv('erroAgendamentoAbertura', data.listOfErrors);
        toast.error('Erro ao agendar abertura');
      } else {
        toast.success('Agendamento da abertura feita com sucesso');
      }
      setIsLoading(false);
      scheduleInvoicesReload();
    } catch (error) {
      toast.error('Erro ao ler o arquivo: ' + error.message);
      setIsLoading(false);
    }
  };

  const handleOpenNow = async (nf = '') => {
    try {
      setIsLoading(true);
      if (nf !== '') {
        toast.info(`Abrindo NF: ${nf}`);
        const res = await serviceOrderService.openScheduleInvoice([nf], user.id);
        if (res.data.length > 0) {
          downloadcsv(res.data);
        }

        toast.success(`A NF: ${nf} foi aberta com sucesso!`);
        //console.log(res.data);
        // res.data?.statusCode === 400
        //   ? toast.error(res.data.message)
        //   : toast.success(`A NF: ${nf} foi aberta com sucesso!`);
      } else {
        if (scheduleInvoices.length > 1) {
          toast.info('Abrindo notas');
          const nfs = scheduleInvoices.map((item) => item.numNf);
          const res = await serviceOrderService.openScheduleInvoice(nfs, user.id);
          if (res.data.length > 0) {
            toast.info('Houve notas erradas.');
            downloadcsv(res.data);
          }

          toast.success('NF(s) foram abertas.');
        } else {
          toast.warning('Não há notas agendadas para abrir.');
        }
      }
    } catch (error) {
      //console.log(error.message);
      toast.error(error.response.data.message || error.message);
    } finally {
      setIsLoading(false);
      scheduleInvoicesReload();
    }
  };

  const handleRemove = async (idCadastro) => {
    toast.info('Deletando NF');
    try {
      setIsLoading(true);
      const res = await serviceOrderService.deleteScheduleInvoice(idCadastro);
      //console.log(res.data);
      toast.success(`NF: ${idCadastro} removida.`);
    } catch (error) {
      //console.log(error.message);
      toast.message('Erro:', error.message);
    } finally {
      setIsLoading(false);
      scheduleInvoicesReload();
    }
  };

  const getNextBusinessDay = () => {
    const today = new Date().getTime();
    const weekDay = new Date(today).getDay();
    const addDay = weekDay === 5 ? 3 : weekDay === 6 ? 2 : 1;
    const date = new Date(today + 3600000 * 24 * addDay).toLocaleDateString('pt-BR');

    return date;
  };

  return (
    <>
      <Dialog maxWidth={'md'} open={isModal}>
        <Dialog.Title onClose={() => handleClose()}>Abertura</Dialog.Title>
        <Dialog.Content>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={12} lg={12}>
              <Typography variant='h5' align='center' style={{ paddingBottom: '1rem' }}>
                Erro ao fazer abertura
              </Typography>
              <Typography align='center'>{logs}</Typography>
            </Grid>
          </Grid>
        </Dialog.Content>
      </Dialog>

      <PageTitle>Agendar Abertura de OS</PageTitle>
      <Formik initialValues={{ invoice_number: invoiceNumber }}>
        {(props) => (
          <Form>
            <input
              type='file'
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileInputChange}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='invoice_number'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='NF'
                  component={Text}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Button fullWidth loading={isLoading} onClick={handleSubmit}>
                  Enviar
                </Button>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Button fullWidth loading={isLoading} onClick={() => handleOpenNow()}>
                  Abrir todas
                </Button>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Button fullWidth loading={isLoading} onClick={handleDownloadModel}>
                  download modelo
                </Button>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button
                  fullWidth
                  loading={isLoading}
                  onClick={handleUploadFileClick}
                  color='secondary'
                >
                  <FileUploadIcon /> importar csv
                </Button>
              </Grid>
            </Grid>
            <Spacer size={32} axis='vertical' />
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <Table
                  headers={columns}
                  maxHeight
                  striped
                  loading={loadingScheduleInvoices}
                  emptyMessage={'Sem NF agendadas'}
                  data={scheduleInvoicesData}
                  title='Notas agendadas'
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  disableNumeration
                >
                  {scheduleInvoices?.map((scheduleInvoice, index) => (
                    <TableRow key={index}>
                      <TableCell>{scheduleInvoice.numNf}</TableCell>
                      <TableCell>{scheduleInvoice.dtAgendado}</TableCell>
                      <TableCell>{getNextBusinessDay()}</TableCell>
                      <TableCell>
                        <Grid container spacing={2}>
                          <Grid item>
                            <Button
                              onClick={() => handleOpenNow(scheduleInvoice.numNf)}
                              loading={isLoading}
                            >
                              Abrir OS
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              onClick={() => handleRemove(scheduleInvoice.numNf)}
                              color='secondary'
                              loading={isLoading}
                            >
                              Remover
                            </Button>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Grid>
            </Grid>
            <Spacer size={32} axis='vertical' />
            {/* <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <span style={{ color: '#F00', fontSize: 16 }}>{logs}</span>
              </Grid>
            </Grid> */}
            {useEffect(() => {
              setInvoiceNumber(props.values.invoice_number);
            }, [props])}
          </Form>
        )}
      </Formik>
    </>
  );
};
