import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Dialog } from 'components';
// import { conferenceService } from 'services';

export const ConfirmDialog = ({ isOpenModal, handleCloseModal, dataInsert }) => {
  const handleSubmit = async (event) => {
    try {
      toast.success('Confirmação feita com sucesso');
    } catch (error) {
      console.log(error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <Dialog
      open={isOpenModal}
      onClose={handleCloseModal}
      title='Conferência Automática'
      maxWidth={'md'}
    >
      <Dialog.Title onClose={handleCloseModal}>Confirmar validação das NF`s</Dialog.Title>
      <Dialog.Content>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <Button fullWidth onClick={handleCloseModal}>
              cancelar
            </Button>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Button fullWidth onClick={handleSubmit}>
              confirmar
            </Button>
          </Grid>
        </Grid>
      </Dialog.Content>
    </Dialog>
  );
};
