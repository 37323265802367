import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Button, Dialog } from 'components';
import { Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';

import { conferenceService } from '../../../../../services/conference';
import { ConfirmationDialog } from './ConfirmationDialog';
import { ErrorDialog } from './ErrorDialog';

const columns = [
  { label: 'Modelo', align: 'center' },
  { label: 'qtdNF', align: 'center' },
  { label: 'Processados', align: 'center' },
];

export const ConferenceDialog = ({
  isModalOpenConference,
  handleCloseModal,
  selectedNote,
  user,
}) => {
  const [selectDataTableApprove, setDataTableApprove] = useState([]);
  const [isSerialDisabled, setIsSerialDisabled] = useState(true);
  const [isPnDisabled, setIsPnDisabled] = useState(false);
  const [selectModelVinc, setModelVinc] = useState([]);
  const [openSubErrorDialog, setOpenSubErrorDialog] = useState(false);
  const [isModalOpenConfirmation, setIsModalOpenConfirmation] = useState(false);
  const [textMessage, setTextMessage] = useState('');
  const [selectStatusReincidencia, setStatusReincidencia] = useState('');
  const [errorDialog, setErrorDialog] = useState('');
  const [typeButton, SetTypeButton] = useState('');
  const [form, setForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFinish, setIsFinish] = useState(false);
  let count = 0;

  const handleCloserSubModal = async () => {
    await setOpenSubErrorDialog(false);
    const input_pn = document.querySelector('[name="input_pn"]');
    if (input_pn) {
      input_pn.focus();
    }
  };

  const handleProcessar = async (values, setFieldValue, resetForm, pn) => {
    const payload = {
      idCabec: selectDataTableApprove[0].idCabec,
      idProdModelo: pn?.[0]?.idProdModelo || selectModelVinc[0].idProdModelo,
      idModelo: pn?.[0]?.idModelo || selectModelVinc[0].idModelo,
      serial: values.input_serial,
      usuario: user.name,
      faltaPeca: values.faltaPeca,
      IdStatusReincidenciaGaia: selectStatusReincidencia,
      imei: '',
      mac: '',
      ativo: '',
      patrimonio: '',
    };

    console.log('play', payload);
    try {
      // Envia uma solicitação para o serviço de processamento com o payload
      const { data } = await conferenceService.processSerialBeeping(payload);
      // Verifica se o processo foi bem-sucedido com base no status da resposta
      if (data?.status === 200) {
        console.log('errou: deu bom');
        toast.success('Processamento realizado com sucesso');
        if (selectStatusReincidencia === 23) {
          setOpenSubErrorDialog(true);
          setErrorDialog('DOA ' + values.input_serial);
        }
        await handelClear(setFieldValue, resetForm);
        await getDados(selectedNote);
      } else {
        console.log('errou: mas foi');
        // Se ocorrer um erro durante o processamento, exibe uma mensagem de erro
        toast.error('Erro ao fazer o processo');
        setOpenSubErrorDialog(true);
        setErrorDialog('Erro ao fazer o processo');
        await handelClear(setFieldValue, resetForm);
      }
    } catch (error) {
      console.log('errou:');
      // Se ocorrer um erro durante o processo de envio da solicitação, exibe uma mensagem de erro
      toast.error('Erro ao processar:' + error.message);
      setOpenSubErrorDialog(true);
      setErrorDialog('Erro ao processar: ' + error.message);
      setIsSerialDisabled(false);
      await handelClear(setFieldValue, resetForm);
    } finally {
      setIsLoading(false);
    }
  };

  const handleProcessarQRCode = async (setFieldValue, resetForm, values) => {
    if (isLoading) return;
    setIsLoading(true);
    console.log('setFieldValue', setFieldValue);
    if (values.qrcode === '') {
      toast.error('Não foi bipado nenhum qrcode');
      return;
    }
    // Dividindo o QR Code em linhas
    const qrCodeData = values.qrcode.split('\n');
    // Acessando a primeira linha para obter o part number
    const partNumber = qrCodeData[0];
    const clienteOPVShort = selectedNote?.ClienteOPV.substring(0, 4).trim();
    // Faz uma verificação, se for bipado um serial de 24, transforma em 20, se for bipado 20 ele processa normal
    let serialNumber;
    if (qrCodeData[1].length === 24) {
      serialNumber = qrCodeData[1].substring(4);
    } else {
      serialNumber = qrCodeData[1];
    }
    // Chamando as funções de validação para PN e Serial
    const pn = await handleGetData(
      selectDataTableApprove[0].idCabec,
      partNumber,
      setFieldValue,
      resetForm,
    );
    console.log(pn);
    if (pn) {
      const sr = await handleCheckSerial(
        selectDataTableApprove[0].idCabec,
        clienteOPVShort,
        qrCodeData[1],
        serialNumber,
      );
      if (sr) {
        const temp = {
          input_pn: partNumber,
          faltaPeca: values.faltaPeca,
          input_serial: serialNumber,
          qrcode: '',
        };
        console.log('oi gente', sr);
        // Se ambos forem válidos, inicia o processo de processamento com os valores extraídos
        await handleProcessar(temp, setFieldValue, resetForm, pn);
      } else {
        setIsLoading(false);
        handelClear(setFieldValue, resetForm);
      }
    } else {
      setIsLoading(false);
    }
  };

  const handleOpenModalZerarColeta = () => {
    // Abre modal para Confirmar zerar Coleta
    setIsModalOpenConfirmation(true);
    setTextMessage('Tem certeza que deseja zerar a coleta?');
    SetTypeButton('zerar');
  };

  const handleOpenModalFinalizarNF = () => {
    setIsModalOpenConfirmation(true);
    setTextMessage('Tem certeza que deseja Finalizar a NF?');
    SetTypeButton('finalizar');
  };

  const handleConfirmDialog = () => {
    if (typeButton !== '' && typeButton === 'zerar') handleResetColletion();
    else handleFinishNF(false);
  };

  const handleCloseSubModal = async () => {
    setIsModalOpenConfirmation(false);
    setOpenSubErrorDialog(false);
  };

  const handleResetColletion = async () => {
    // Cria um objeto temporário contendo os dados da coleção selecionada
    const temp = selectDataTableApprove[0];
    if (!selectDataTableApprove[0]?.idCabec) {
      toast.error('Erro ao pegar o informação atualize a tela e tente novamente');
      return;
    }
    // Cria um novo objeto com os dados da coleção selecionada e os IDs da nota e do registro selecionados
    const temp2 = {
      ...temp,
      selectedNote: selectedNote,
      selectedIdRegistro: selectedNote.idRegistro,
    };
    try {
      // Envia uma solicitação para redefinir a coleção com os dados atualizados
      const { data } = await conferenceService.resetCollection(temp2);
      // Exibe uma mensagem de sucesso e atualiza o estado da tabela de dados da coleção
      toast.success('Coleta Zerada com Sucesso');
      setDataTableApprove(data);
      // Fecha o modal de confirmação de zeramento da coleção
      await handleCloseSubModal();
    } catch (error) {
      // Se ocorrer um erro durante o reset da coleção, exibe uma mensagem de erro
      toast.error('Erro ao resetar a coleção.');
    }
  };

  const handleFinishNF = async (finished) => {
    const info = {
      idCabec: selectDataTableApprove[0].idCabec,
      finalizada: finished,
      selectedNote: selectedNote,
      usuario: user.name,
    };
    try {
      // Envia uma solicitação para finalizar a NF com os parâmetros necessários
      const { data } = await conferenceService.getFinishNF(info);
      // Verifica se a finalização da NF foi bem-sucedida
      if (data.status === 200) {
        // Exibe mensagens de sucesso
        if (data.idStatus === 3) {
          toast.error(data.msg);
        } else if (data.idStatus === 5) {
          toast.info(data.msg);
        } else {
          toast.success(data.msg);
        }
        // Atualiza a tabela de dados da coleção
        // Fecha o modal de finalização da NF e o modal principal
        await handleCloseSubModal();
        await handleCloseModal();
      } else {
        // Se a finalização da NF não for bem-sucedida, exibe uma mensagem de erro
        toast.error(data.msg);
        // Atualiza a tabela de dados da coleção
        // Fecha o modal de finalização da NF e o modal principal
        await handleCloseSubModal();
        await handleCloseModal();
      }
    } catch (error) {
      // Se ocorrer um erro durante o processo de finalização da NF, exibe uma mensagem de erro
      toast.error('Erro ao finalizar NF.');
    }
  };

  const handelClear = async (setFieldValue, resetForm) => {
    setFieldValue('input_pn', '');
    setFieldValue('input_vincModelo', '');
    setFieldValue('input_serial', '');
    setFieldValue('qrcode', '');
    setFieldValue('faltaPeca', false);
    setModelVinc([]);
    setIsPnDisabled(false); // Habilitar o campo input_pn novamente
    resetForm();
  };

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const handleCheckSerial = async (idCabec, ClienteOPV, serial, serial20dig) => {
    let responseData;
    try {
      // Chama o serviço para obter informações sobre o número de série
      const { data } = await conferenceService.getModelSerialBeeping({
        idCabec,
        ClienteOPV,
        serial,
        serial20dig,
      });

      responseData = data;
      // Verifica o status da resposta do serviço
      if (data?.status === 404 || data?.status === 409) {
        // Se o número de série não for encontrado ou houver um conflito, define o status de serial desativado e exibe uma mensagem de erro
        setIsSerialDisabled(true);
        toast.error(data?.msg);
        setErrorDialog(data?.msg);
        setOpenSubErrorDialog(true);
        return false;
      } else {
        // Se o número de série for verificado com sucesso, exibe uma mensagem de sucesso e define o status de serial ativado
        toast.success(data.response[0].Mensagem);
        setIsSerialDisabled(false);
        setStatusReincidencia(data.response[0].IdStatusReincidencia);
        return true;
      }
    } catch (error) {
      console.error(error);
      // Se ocorrer um erro durante a verificação do número de série, exibe uma mensagem de erro
      toast.error('Erro ao verificar serial.');
      setErrorDialog(responseData.response[0].Mensagem);
      setOpenSubErrorDialog(true);
      return false;
    }
  };

  const handleGetData = async (idCabec, partnumber, setFieldValue, resetForm) => {
    try {
      // Chama o serviço para obter os dados do modelo
      const { data } = await conferenceService.getModelBeeping({
        idCabec: idCabec,
        partnumber: partnumber,
      });
      // Verifica o status da resposta do serviço
      if (data.status === 404) {
        // Se os dados do modelo não forem encontrados, exibe uma mensagem de erro e limpa o modelo vinculado
        toast.error(data.msg);
        setModelVinc([]);
        handelClear(setFieldValue, resetForm);
        return false;
      } else {
        // Se os dados do modelo forem encontrados, define o modelo vinculado e o ID do modelo
        setModelVinc(data);
        return data;
      }
    } catch (error) {
      // Se ocorrer um erro durante a solicitação, exibe uma mensagem de erro e retorna falso
      toast.error('Erro ao buscar dados do modelo.');
      return false;
    }
  };

  const handleValidationPn = async (e, setFieldValue, resetForm) => {
    let validationPn = true;
    if (e.key === 'Enter') {
      validationPn = await handleGetData(
        selectDataTableApprove[0].idCabec,
        e.target.value,
        setFieldValue,
        resetForm,
      );
      if (validationPn) {
        const serialField = document.querySelector('[name="input_serial"]');
        if (serialField) {
          serialField.focus();
        }
        if (e.target.value) {
          setIsPnDisabled(true); // Desabilitar o campo após entrada de valor
        }
      }
    }
  };

  const handleValidationSerial = async (e, setFieldValue, resetForm) => {
    if (e.key === 'Enter') {
      const idCabec = await selectDataTableApprove[0].idCabec;
      const serial = await e.target.value;
      let serial20dig = await e.target.value;

      if (serial.length === 24) {
        serial20dig = await serial.substring(4, 24);
      } else if (serial.length === 20) {
        serial20dig = await serial;
      }
      // Extrair os 4 primeiros caracteres do ClienteOPV
      const clienteOPVShort = selectedNote?.ClienteOPV.substring(0, 4).trim();
      const validation = await handleCheckSerial(idCabec, clienteOPVShort, serial, serial20dig);

      if (validation) {
        const serialProcess = document.querySelector('[name="btn_processar"]');

        if (serialProcess) {
          await serialProcess.click();
        } else {
          console.error('Botão "btn_processar" não encontrado.');
        }
      } else {
        await handelClear(setFieldValue, resetForm);
        const input_pn = document.querySelector('[name="input_pn"]');
        if (input_pn) {
          input_pn.focus();
        }
      }
    }
  };

  const getDados = useCallback(async (item) => {
    console.log('getDados item:', item);
    try {
      const { data } = await conferenceService.getDataTableModeloConferenceBeeping(
        item.Nota,
        item.idRegistro,
      );
      console.log('data', data);
      // Verifica se a NF está finalizada
      if (data[0].Processados === data[0].qtdNf) {
        console.log('é igual');
        setIsFinish(true);
        handleOpenModalFinalizarNF();
      }
      console.log('getData', data);
      setDataTableApprove(data);
    } catch (error) {
      toast.error('Falha ao pegar os dados.');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isModalOpenConference) {
      getDados(selectedNote);
    }
  }, [selectedNote, isModalOpenConference, getDados]);

  const BeepingButton = ({ setFieldValue, resetForm, values }) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12} md={12}>
          <Typography variant='h6' style={{ paddingTop: '2rem' }}>
            Coleta de Dados
          </Typography>
        </Grid>
        <Grid item xs={12} lg={4} md={4} sm={4}>
          <Field
            size='small'
            name='input_pn'
            label='PN'
            variant='outlined'
            component={Text}
            disabled={isPnDisabled}
            autoComplete='off'
            onClick={() => {
              handelClear(setFieldValue, resetForm);
            }}
            onKeyDown={(e) => handleValidationPn(e, setFieldValue, resetForm)}
          />
        </Grid>
        <Grid item xs={12} lg={4} md={4} sm={4}>
          <Field
            size='small'
            name='input_vincModelo'
            variant='outlined'
            component={Text}
            disabled
            autoComplete='off'
            value={selectModelVinc[0]?.modelo || ''}
          />
        </Grid>
        <Grid item xs={12} lg={4} md={4} sm={4}>
          <Field
            size='small'
            name='input_serial'
            label='Serial'
            variant='outlined'
            component={Text}
            autoComplete='off'
            onKeyDown={async (e) => handleValidationSerial(e, setFieldValue, resetForm)}
          />
        </Grid>
        <Grid item xs={12} lg={3} md={3} sm={4}>
          <Button
            fullWidth
            name='btn_processar'
            disabled={isSerialDisabled || isFinish || isLoading}
            onClick={async () => {
              await handleProcessar(values, setFieldValue, resetForm);
              await document.querySelector('[name="input_pn"]').focus();
            }}
          >
            Processar
          </Button>
        </Grid>
      </Grid>
    );
  };

  const qrCodeRead = ({ setFieldValue, resetForm, values }) => {
    return (
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={12} lg={12}>
          <Field
            size='small'
            name='qrcode'
            label='QR Code'
            variant='outlined'
            component={Text}
            multiline
            minRows={8}
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                count += 1;
                if (count === 3) {
                  count = 0;
                  await sleep(100);
                  document.querySelector('[name="btn_processar_qrcode"]').click();
                }
              }
            }}
          />
        </Grid>
        <Grid item xs={12} lg={3} md={3} sm={4}>
          <Button
            fullWidth
            disabled={isFinish || isLoading}
            name='btn_processar_qrcode'
            onClick={async () => {
              await handleProcessarQRCode(setFieldValue, resetForm, values);
            }}
          >
            Processar
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <ErrorDialog
        openSubErrorDialog={openSubErrorDialog}
        handleCloseSubModal={handleCloserSubModal}
        errorDialog={errorDialog}
      />
      <ConfirmationDialog
        isModalOpenConfirmation={isModalOpenConfirmation}
        handleCloseSubModal={handleCloseSubModal}
        handleConfirmDialog={handleConfirmDialog}
        textMessage={textMessage}
      />
      <Dialog maxWidth={'md'} open={isModalOpenConference}>
        <Dialog.Title
          onClose={() =>
            isLoading ? toast.warning('Aguarde a finalização do processo') : handleCloseModal()
          }
        >
          Conferência
        </Dialog.Title>
        <Dialog.Content>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={12} lg={12}>
              <Typography variant='h5' align='center' style={{ paddingBottom: '1rem' }}>
                Informações da Nota
              </Typography>
              <Typography align='center'>NF: {selectedNote.Nota}</Typography>
              <Typography align='center'>Cliente: {selectedNote.ClienteOPV}</Typography>
            </Grid>
          </Grid>
          <Formik
            initialValues={{ input_pn: '', input_serial: '', faltaPeca: false, qrcode: '' }}
            onSubmit={handleProcessar}
          >
            {({ setFieldValue, resetForm, values }) => (
              <Form>
                <Grid container spacing={2} justifyContent='center'>
                  <Grid item xs={8}>
                    <Table
                      name='Table2'
                      headers={columns}
                      maxHeight
                      size='small'
                      headerFontColor='#fff'
                      disableNumeration
                    >
                      {selectDataTableApprove.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell align='center'>{item.Modelo}</TableCell>
                          <TableCell align='center'>{item.qtdNf}</TableCell>
                          <TableCell align='center'>{item.Processados}</TableCell>
                        </TableRow>
                      ))}
                    </Table>
                  </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent='center'>
                  <Grid item xs={3} sm={3} md={3} lg={3}>
                    <Button fullWidth color='secondary' onClick={handleOpenModalZerarColeta}>
                      zerar Coleta
                    </Button>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3}>
                    <Button fullWidth onClick={handleOpenModalFinalizarNF}>
                      finalizar NF
                    </Button>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3}>
                    <Button
                      id='clearButton'
                      onClick={async () => {
                        await handelClear(setFieldValue, resetForm);
                        document.querySelector('[name="input_pn"]').focus();
                      }}
                      fullWidth
                    >
                      limpar
                    </Button>
                  </Grid>
                </Grid>
                <Grid container justifyContent='center'>
                  <Typography variant='h6' style={{ paddingTop: '4rem' }}>
                    Selecione o tipo de Bipagem:
                  </Typography>
                </Grid>
                <Grid container justifyContent='center' alignItems='center'>
                  <FormatAlignJustifyIcon
                    style={{ fontSize: '5rem', cursor: 'pointer', marginRight: '2rem' }}
                    onClick={() => setForm(false)}
                  />
                  <QrCode2Icon
                    style={{ fontSize: '5rem', cursor: 'pointer' }}
                    onClick={() => setForm(true)}
                  />
                </Grid>
                <Grid container spacing={2} justifyContent='center' alignItems='center'>
                  <FormGroup>
                    <FormControlLabel
                      required
                      control={<Checkbox />}
                      name='faltaPeca'
                      label='Falta de Peças'
                      style={{ paddingTop: '2rem' }}
                      onChange={(event) => setFieldValue('faltaPeca', event.target.checked)}
                    />
                  </FormGroup>
                </Grid>
                {!form
                  ? BeepingButton({ setFieldValue, resetForm, values })
                  : qrCodeRead({ setFieldValue, resetForm, values })}
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
