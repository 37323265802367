import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { Grid } from '@mui/material';
import { Button, DatePicker } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadXlsx } from 'helpers/downloadXLSX';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services';

export const DoaBounceReport = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    setIsLoading(true);
    setSubmitting(true);
    try {
      const inputDate = new Date(values.input_data);
      const outputDate = new Date(values.output_data);
      const differenceInTime = outputDate - inputDate;
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);

      if (differenceInDays > 7) {
        toast.error('O intervalo entre as datas deve ser no máximo de 7 dias.');
        setIsLoading(false);
        setSubmitting(false);
        return;
      }

      const data = await providerService.getDoaBounceReport(values);
      downloadXlsx('ingenico_doaBounce', data);
      toast.success('Relatório gerado com sucesso');
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setSubmitting(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório DoaBounce</PageTitle>
      <Formik
        initialValues={{
          input_data: new Date(new Date().setDate(new Date().getDate() - 7)),
          output_data: new Date(),
        }}
        onSubmit={handleSubmit}
      >
        {({ values, resetForm }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} lg={3}>
                <Field
                  size='small'
                  loading={isLoading}
                  name='input_data'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Data inicial'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Field
                  size='small'
                  loading={isLoading}
                  name='output_data'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Data final'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Button fullWidth type='submit' loading={isLoading}>
                  Exportar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
