export const downloadcsv = (namefile, data) => {
  const now = new Date();
  const formatDate = now.toLocaleDateString('pt-BR').replace(/\//g, '_');
  const formatTime = now
    .toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })
    .replace(/:/g, '_');
  const csvContent = [
    Object.keys(data[0]).join(';'), // Headers
    ...data.map((item) => Object.values(item).join(';')), // Data rows
  ].join('\n');

  const csvData = new Blob([csvContent], { type: 'text/csv' });
  const csvUrl = window.URL.createObjectURL(csvData);
  const link = document.createElement('a');
  link.href = csvUrl;
  link.setAttribute('download', `${namefile}_${formatDate}_${formatTime}.csv`);
  document.body.appendChild(link);
  link.click();
};
