import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { DatePicker, Select, Button } from 'components';
import { saveAs } from 'file-saver';
import { Formik, Field, Form } from 'formik';
import { downloadcsv } from 'helpers/downloadcsv';
import { AuthContext } from 'hooks/useAuth';
import { PageTitle } from 'pages/Layout/PageTitle';
import { launchPrivider } from 'services';
import { useContextSelector } from 'use-context-selector';

export const CompactReport = () => {
  const [clientOption, setClientOption] = useState([]);
  const [conditiontOption, setconditionOption] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const user = useContextSelector(AuthContext, (context) => context.user);

  const onSubmitHandler = async (values) => {
    setIsLoading(true);
    if (values?.client.length === 0) {
      setIsLoading(false);
      toast.error('Não foi selecionado um cliente');
      return;
    }
    toast.info('Aguarde o sistema gerar os laudos');
    try {
      // Verifica se a diferença entre as datas é de até 15 dias
      const startDate = new Date(values.start_date);
      const endDate = new Date(values.end_date);
      const diffInDays = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
      if (diffInDays > 15) {
        // Informar ao usuário e rejeitar o envio
        toast.warning('A data de saída deve ser dentro dos 15 dias após a data de entrada.');
        return;
      }

      const temp = { ...values, user: user };

      const { data } = await launchPrivider.generateCompressedReport(temp);
      const file = new Blob([data], { type: 'application/zip' });
      saveAs(file, 'compactarLaudo.zip');
      toast.success('Zip gerado com sucesso.');
    } catch (error) {
      if (error.response && error.response.data instanceof Blob) {
        const errorBlob = error.response.data;
        const reader = new FileReader();
        reader.onload = () => {
          const errorMessage = JSON.parse(reader.result).message || 'Erro desconhecido';
          toast.error('Erro ao gerar o relatório: ' + errorMessage);
        };
        reader.onerror = () => {
          toast.error('Erro ao gerar o relatório: Erro ao ler a mensagem de erro');
        };
        reader.readAsText(errorBlob);
      } else {
        // Captura a mensagem de erro correta se não for um Blob
        const errorMessage = error.response?.data?.message || error.message || 'Erro desconhecido';
        toast.error('Erro ao gerar o relatório: ' + errorMessage);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleExport = async (values) => {
    if (values.client.length === 0) {
      toast.error('Não foi possivel extrair relatório, selecione pelo menos um cliente.');
      return;
    }
    setIsLoading(true);
    toast.info('Aguarde ...');
    try {
      const { data } = await launchPrivider.reportsServiceOrdem(values);
      downloadcsv('ingenico_compactar_laudos', data);
      toast.info('Sucesso.');
    } catch (error) {
      toast.error('Não foi possivel extrair relatório.');
    } finally {
      setIsLoading(false);
    }
  };

  const getListClient = useCallback(async () => {
    const jsonObject = JSON.parse(localStorage.getItem('@Gaia:user'));
    const { data } = await launchPrivider.getListClient(jsonObject.role.alias.toUpperCase());
    setClientOption(data);
  }, []);

  const getListCondition = useCallback(async () => {
    const { data } = await launchPrivider.getListCondition();
    setconditionOption(data);
  }, []);

  useEffect(() => {
    getListClient();
    getListCondition();
  }, [getListClient, getListCondition]);
  return (
    <>
      <PageTitle>Compactar Laudo</PageTitle>
      <Formik
        initialValues={{
          start_date: new Date(),
          end_date: new Date(),
          client: [],
          condition: 'TODOS',
        }}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <Field
                    size='small'
                    name='start_date'
                    variant='inline'
                    fullWidth
                    inputVariant='outlined'
                    label='Data de entrada'
                    component={DatePicker}
                    loading={isLoading}
                    disabled={false}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    size='small'
                    name='end_date'
                    variant='inline'
                    fullWidth
                    inputVariant='outlined'
                    label='Data de saida'
                    component={DatePicker}
                    // OnChange={() => setOutputData(props.values.output_data)}
                    loading={isLoading}
                    disabled={false}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    multiple
                    size='small'
                    name='client'
                    label='Selecione o cliente'
                    variant='outlined'
                    options={clientOption}
                    component={Select}
                    loading={isLoading}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    size='small'
                    name='condition'
                    label='Selecione a condição'
                    variant='outlined'
                    options={conditiontOption}
                    component={Select}
                    loading={isLoading}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Button
                    variant='contained'
                    color='primary'
                    fullWidth
                    disabled={isLoading}
                    loading={isLoading}
                    type='submit'
                  >
                    gerar arquivo.zip
                  </Button>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Button
                    variant='contained'
                    color='primary'
                    fullWidth
                    disabled={isLoading}
                    loading={isLoading}
                    onClick={() => handleExport(props.values)}
                  >
                    exportar planilha
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
