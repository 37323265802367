import React, { useState, useCallback, useEffect } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { Text, Select, Button, Table } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { launchPrivider } from 'services';

import { statusFreezing } from '../../../../helpers/getStatusFreezing';
import { typeFreezing } from '../../../../helpers/getTypeFreezing';
import { DialogChangeStatus } from './DialogChangeStatus';
import { DialogUpdateRequest } from './DialogDialogUpdateRequest';
import { DialogRequestFreezing } from './DialogRequestFreezing';
import { DialogResendEmail } from './DialogResendEmail';

const columns = [
  { label: 'ID' },
  { label: 'Data Cadastro' },
  { label: 'Criador' },
  { label: 'Tipo Congelamento' },
  { label: 'Assunto' },
  { label: 'Responsável' },
  { label: 'Status' },
  { label: 'Reenviar E-mail' },
];

const FreezingSystemSlas = () => {
  const [dataTable, setDataTable] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogStatus, setOpenDialogStatus] = useState(false);
  const [openDialogUpdateRequest, setOpenDialogUpdateRequest] = useState(false);
  const [openDialogResendEmail, setOpenDialogResendEmail] = useState(false);
  const [typesFreezing, setTypeFreezing] = useState([{ label: '', value: '' }]);
  const [typeStatus, setStatusFreezing] = useState([{ label: '', value: '' }]);
  const [idCadastro, setIdCadastro] = useState(0);
  const [idCadastroUpdateRequest, setIdCadastroUpdateRequest] = useState(0);

  const getDados = useCallback(async () => {
    const dataTypeFreezing = await typeFreezing();
    const firstValue = [{ label: 'TODOS', value: 0 }, ...dataTypeFreezing];

    const dataStatusFreezing = await statusFreezing();
    const firstValueStatus = [{ label: 'TODOS', value: 0 }, ...dataStatusFreezing];
    setTypeFreezing(firstValue);
    setStatusFreezing(firstValueStatus);

    handleDataTable({ id_call: '', responsible: '', type_freezing: '', status: '1' });
  }, []);

  useEffect(() => {
    getDados();
  }, [getDados]);

  const handleOpenDialogResendEmail = () => {
    setOpenDialogResendEmail(true);
  };

  const handleCloseDialogResendEmail = () => {
    setOpenDialogResendEmail(false);
  };

  const handleOpenDialogUpdateRequest = (id_cadastro) => {
    setIdCadastroUpdateRequest(id_cadastro);
    setOpenDialogUpdateRequest(true);
  };

  const handleCloseDialogUpdateRequest = () => {
    setOpenDialogUpdateRequest(false);
  };

  const handleOpenDialogStatus = (id_cadastro) => {
    setIdCadastro(id_cadastro);
    setOpenDialogStatus(true);
  };
  const handleCloseDialogStatus = () => {
    setOpenDialogStatus(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDataTable = async (values) => {
    const { data } = await launchPrivider.getDataTable(values);
    setDataTable(data);
  };
  const handleResetTable = () => {
    handleDataTable({ id_call: '', responsible: '', type_freezing: '', status: '1' });
  };

  const getButtonColor = (status) => {
    switch (status.toLowerCase()) {
      case 'solicitado':
        return 'primary';
      default:
        return 'default';
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <DialogRequestFreezing handleCloseDialog={handleCloseDialog} openDialog={openDialog} />
      <DialogChangeStatus
        idCadastro={idCadastro}
        handleCloseDialogStatus={handleCloseDialogStatus}
        openDialogStatus={openDialogStatus}
      ></DialogChangeStatus>
      <DialogUpdateRequest
        idCadastroUpdateRequest={idCadastroUpdateRequest}
        handleCloseDialogUpdateRequest={handleCloseDialogUpdateRequest}
        openDialogUpdateRequest={openDialogUpdateRequest}
      ></DialogUpdateRequest>
      <DialogResendEmail
        openDialogResendEmail={openDialogResendEmail}
        handleCloseDialogResendEmail={handleCloseDialogResendEmail}
      ></DialogResendEmail>

      <PageTitle>Sistema de Controle de Congelamento SLAs</PageTitle>

      <Formik
        initialValues={{
          id_call: '',
          responsible: '',
          type_freezing: 0,
          status: 0,
        }}
        onReset={handleResetTable}
        onSubmit={handleDataTable}
      >
        {(props) => (
          <Form>
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <Field
                    size='small'
                    name='id_call'
                    label='ID: Chamado'
                    type='text'
                    variant='outlined'
                    value={props.values.id_call}
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    size='small'
                    name='responsible'
                    label='Responsável'
                    type='text'
                    variant='outlined'
                    value={props.values.responsible}
                    component={Text}
                    disabled={props.values.id_call > 0}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    size='small'
                    name='type_freezing'
                    label='Tipo de Congelamento'
                    variant='outlined'
                    options={typesFreezing}
                    component={Select}
                    disabled={props.values.id_call > 0}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    size='small'
                    name='status'
                    label='Status'
                    variant='outlined'
                    options={typeStatus}
                    component={Select}
                    disabled={props.values.id_call > 0}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item lg={3}>
                  <Button type='submit' fullWidth>
                    Pesquisar
                  </Button>
                </Grid>
                <Grid item lg={3}>
                  <Button type='reset' fullWidth color='secondary'>
                    Limpar
                  </Button>
                </Grid>
                <Grid item xs={12} lg={3} align='end'></Grid>
                <Grid item xs={12} lg={3} align='end'>
                  <Button fullWidth onClick={handleOpenDialog}>
                    Solicitar Aprovação
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Table name='Table' headers={columns} maxHeight disableNumeration>
                    {dataTable &&
                      dataTable
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Button
                                onClick={() => handleOpenDialogUpdateRequest(item.id_cadastro)}
                                variant='outlined'
                              >
                                {item.id_cadastro}
                              </Button>
                            </TableCell>
                            <TableCell>{item.dt_cadastro}</TableCell>
                            <TableCell>{item.criador}</TableCell>
                            <TableCell>{item.tipo_congelamento}</TableCell>
                            <TableCell>{item.assunto}</TableCell>
                            <TableCell>{item.ResponsavelIngenico}</TableCell>
                            <TableCell>
                              <Button
                                onClick={() => handleOpenDialogStatus(item.id_cadastro)}
                                color={getButtonColor(item.status)}
                                disabled={item.status != 'Solicitado'}
                              >
                                {item.status}
                              </Button>
                            </TableCell>
                            <TableCell>
                              <Button
                                onClick={handleOpenDialogResendEmail}
                                color='secondary'
                                fullWidth
                              >
                                Reenviar Email
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component='div'
                    count={dataTable.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
export { FreezingSystemSlas };
