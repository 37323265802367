import React from 'react';
import { BsPencilSquare } from 'react-icons/bs';
import { CiAlarmOn } from 'react-icons/ci';
import { FaBoxOpen, FaFileAlt } from 'react-icons/fa';
import { GiMailbox } from 'react-icons/gi';
import { ImDrawer, ImUserCheck } from 'react-icons/im';

import CancelIcon from '@material-ui/icons/Cancel';
import LayersClearIcon from '@material-ui/icons/LayersClear';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CallIcon from '@mui/icons-material/Call';
import DevicesIcon from '@mui/icons-material/Devices';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import HomeIcon from '@mui/icons-material/Home';
import LaunchIcon from '@mui/icons-material/Launch';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import ScienceIcon from '@mui/icons-material/Science';
import StarIcon from '@mui/icons-material/Star';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

export const iconMap = {
  HomeIcon: <HomeIcon />,
  StarIcon: <StarIcon />,
  BsPencilSquare: <BsPencilSquare size='18' />,
  AttachMoneyIcon: <AttachMoneyIcon />,
  CancelIcon: <CancelIcon />,
  FaBoxOpen: <FaBoxOpen size='18' />,
  LaunchIcon: <LaunchIcon size='18' />,
  LayersClearIcon: <LayersClearIcon />,
  LocalShippingIcon: <LocalShippingIcon />,
  PlaylistAddIcon: <PlaylistAddIcon />,
  PlaylistAddCheckIcon: <PlaylistAddCheckIcon />,
  DoneAllIcon: <DoneAllIcon />,
  VerifiedUserIcon: <VerifiedUserIcon />,
  ImDrawer: <ImDrawer />,
  SupervisedUserCircleIcon: <SupervisedUserCircleIcon size='18' />,
  FaFileAlt: <FaFileAlt />,
  ImUserCheck: <ImUserCheck size='18' />,
  DevicesIcon: <DevicesIcon />,
  GiMailbox: <GiMailbox />,
  PersonAddIcon: <PersonAddIcon />,
  CallIcon: <CallIcon />,
  SettingsBackupRestoreIcon: <SettingsBackupRestoreIcon />,
  SummarizeIcon: <SummarizeIcon />,
  CiAlarmOn: <CiAlarmOn size='18' />,
  ScienceIcon: <ScienceIcon />,
};

export const iconSwitch = (roles) => {
  switch (roles) {
    case 1:
      return iconMap.HomeIcon;
    case 2:
      return iconMap.BsPencilSquare;
    case 3:
      return iconMap.AttachMoneyIcon;
    case 4:
      return iconMap.FaBoxOpen;
    case 5:
      return iconMap.LocalShippingIcon;
    case 19:
      return iconMap.PlaylistAddCheckIcon;
    case 21:
      return iconMap.SpeedIcon;
    case 6:
      return iconMap.VerifiedUserIcon;
    case 7:
      return iconMap.ImDrawer;
    case 8:
      return iconMap.SupervisedUserCircleIcon;
    case 9:
      return iconMap.FaFileAlt;
    case 10:
      return iconMap.ImUserCheck;
    case 11:
      return iconMap.DevicesIcon;
    case 12:
      return iconMap.GiMailbox;
    case 20:
      return iconMap.GiMailbox;
    case 13:
      return iconMap.CallIcon;
    case 14:
    case 151:
    case 152:
    case 162:
      return iconMap.PersonAddIcon;
    case 16:
      return iconMap.SummarizeIcon;
    case 17:
      return iconMap.SummarizeIcon;
    case 18:
      return iconMap.SummarizeIcon;
    case 140:
      return iconMap.CiAlarmOn;
    case 300:
    case 182:
    case 192:
    case 199:
    case 200:
    case 201:
    case 202:
    case 203:
    case 204:
    case 205:
    case 206:
    case 207:
    case 208:
    case 209:
    case 210:
    case 211:
    case 212:
    case 213:
    case 214:
    case 215:
    case 216:
    case 217:
    case 218:
    case 219:
    case 220:
    case 221:
    case 222:
    case 223:
    case 224:
    case 225:
    case 226:
    case 227:
    case 228:
    case 229:
    case 230:
    case 231:
    case 232:
    case 233:
    case 234:
    case 235:
    case 236:
    case 237:
    case 238:
    case 239:
    case 240:
    case 241:
    case 242:
    case 243:
    case 244:
    case 245:
    case 246:
    case 247:
    case 248:
    case 249:
    case 250:
    case 251:
    case 252:
    case 253:
      return iconMap.DoneAllIcon;
    case 400:
    case 401:
    case 402:
    case 403:
    case 404:
    case 406:
      return iconMap.LaunchIcon;
    case 500:
    case 604:
      return iconMap.PlaylistAddIcon;
    case 601:
      return iconMap.LayersClearIcon;
    case 602:
      return iconMap.SettingsBackupRestoreIcon;
    case 603:
      return iconMap.CancelIcon;
    case 800:
    case 801:
      return iconMap.ScienceIcon;
    default:
      return null; // ou algum ícone padrão caso role não seja encontrado
  }
};
