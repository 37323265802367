import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadXlsx } from 'helpers/downloadXLSX';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services/providers';

export const ExpeditionServiceOrderReport = () => {
  const [inputData, setInputData] = useState(new Date());
  const [outputData, setOutputData] = useState(new Date());
  const [type, setType] = useState('0');

  const [isLoading, setIsLoading] = useState(0);

  const handleExportReport = async () => {
    toast.info('Aguarde, relatório sendo gerado.');
    setIsLoading(1);
    const formattedInputDate = inputData.toLocaleDateString().replace('/', '-').replace('/', '-');
    const formattedOutputDate = outputData.toLocaleDateString().replace('/', '-').replace('/', '-');
    try {
      const { data } = await providerService.getExpeditionServiceOrderReport(
        formattedInputDate,
        formattedOutputDate,
        type,
      );
      downloadXlsx('ingenico_oss_expedidas', data);
      toast.success('Relatório gerado com sucesso');
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    } finally {
      setIsLoading(0);
    }
  };

  const selectRangeOptions = [
    {
      value: '0',
      label: 'Resumido',
    },
    {
      value: '1',
      label: 'Detalhado',
    },
  ];

  return (
    <>
      <PageTitle>Relatório OSs Expedidas</PageTitle>
      <Formik
        initialValues={{
          input_data: inputData,
          output_data: outputData,
          type_data: type,
        }}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='input_data'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Data inicial'
                  component={DatePicker}
                  disabled={isLoading == 1 ? true : false}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='output_data'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Data final'
                  component={DatePicker}
                  disabled={isLoading == 1 ? true : false}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='type_data'
                  label='Tipo'
                  variant='outlined'
                  options={selectRangeOptions}
                  component={Select}
                  disabled={isLoading == 1 ? true : false}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button
                  fullWidth
                  onClick={handleExportReport}
                  disabled={isLoading == 1 ? true : false}
                  loading={isLoading == 1 ? true : false}
                >
                  Exportar relatório
                </Button>
              </Grid>
            </Grid>
            {useEffect(() => {
              setInputData(props.values.input_data);
              setOutputData(props.values.output_data);
              setType(props.values.type_data);
            }, [props])}
          </Form>
        )}
      </Formik>
    </>
  );
};
