import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import DescriptionIcon from '@mui/icons-material/Description';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Button, Text, Select, Table } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services';

const type = [
  {
    value: 'numeroOs',
    label: 'OS CAR',
  },
  {
    value: 'serial',
    label: 'serial',
  },
  {
    value: 'serialIN',
    label: 'serialIN',
  },
  {
    value: 'TrackId',
    label: 'TrackId',
  },
  {
    value: 'IMEI',
    label: 'IMEI',
  },
];

const columns = [
  { label: 'OS CAR' },
  { label: 'Serial' },
  { label: 'Cliente' },
  { label: 'Status' },
  { label: 'Data' },
  { label: 'Detalhes' },
  { label: 'Laudo Perda' },
];

const columns_status = [
  { label: 'Tipo Status' },
  { label: 'Status' },
  { label: 'Data' },
  { label: 'Observação' },
];

const columns_CMP_HF = [
  { label: 'Tipo CMP' },
  { label: 'Observação' },
  { label: 'Data Entrada' },
  { label: 'Data Saída' },
];

const columns_dados_cliente = [
  { label: 'Nome' },
  { label: 'CPF/CNPJ' },
  { label: 'Endereço' },
  { label: 'Número' },
  { label: 'Bairro' },
  { label: 'Cidade' },
  { label: 'Estado' },
  { label: 'CEP' },
];

const columns_detalhes_laudo = [
  { label: 'Descrição' },
  { label: 'Detalhes' },
  { label: 'Categoria' },
];

export const StatusOs = () => {
  const [loading, isLoading] = useState(false);
  const [roleData, setRoleData] = useState([]);
  const [info, setInfo] = useState({});
  const [showInfo, setShowInfo] = useState(false);
  const [buttonLaudo, setButtonLaudo] = useState(false);

  const handleSubmit = async (values, { setSubmitting }) => {
    setRoleData([]);
    setShowInfo(false);
    setSubmitting(true);
    isLoading(true);
    toast.info('Pesquisando ...');
    try {
      const response = await providerService.getInfoOsStatus(values);
      toast.success('Dados encontrados');
      setRoleData(response);
    } catch (error) {
      toast.error('Não foi possivel encontrar dados.');
    } finally {
      setSubmitting(false);
      isLoading(false);
    }
  };

  const hanldeGetAllInfo = async (data) => {
    setShowInfo(true);
    setButtonLaudo(true);
    toast.info('Pesquisando ...');
    try {
      const response = await providerService.getAllInfo(data);
      setInfo(response);
      toast.success('Dados encontrados');
    } catch (error) {
      toast.error('Não foi possivel encontrar dados.');
    } finally {
      setButtonLaudo(false);
    }
  };

  const hanldeGetDownloadLaudo = async (obj) => {
    toast.info('Aguarde o Laudo ser gerado');
    try {
      const { data } = await providerService.reportGenerator(obj);
      const file = new Blob([data], { type: 'application/pdf' });
      saveAs(file, 'laudo.pdf');
      toast.success('Laudo gerado com sucesso.');
    } catch (error) {
      toast.error('Não foi possivel gerar laudo.');
    }
  };

  const handleDetail = () => {
    return (
      <Formik>
        <Form>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} xl={3}>
                <Field
                  variant='outlined'
                  label='OS CAR'
                  size='small'
                  fullWidth
                  value={info?.getDetalheStatus?.[0]?.oscar_ingenico || ''}
                  name='oscar_ingenico'
                  component={Text}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3} xl={3}>
                <Field
                  variant='outlined'
                  label='OS PARCEIRO'
                  size='small'
                  fullWidth
                  value={info?.getDetalheStatus?.[0]?.osParceiro || '-----'}
                  name='osParceiro'
                  component={Text}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3} xl={3}>
                <Field
                  variant='outlined'
                  label='Atendimento'
                  size='small'
                  fullWidth
                  value={info?.getDetalheStatus?.[0]?.tipoAtendimento || ''}
                  name='tipoAtendimento'
                  component={Text}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3} xl={3}>
                <Field
                  variant='outlined'
                  label='Gerada em'
                  size='small'
                  fullWidth
                  value={info?.getDetalheStatus?.[0]?.dtCadastro || ''}
                  name='dtCadastro'
                  component={Text}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} xl={12}>
                <Typography variant='h6' fontWeight='bold'>
                  Dados do Aparelho:
                </Typography>
              </Grid>
              <Grid item xs={12} md={3} xl={3}>
                <Field
                  variant='outlined'
                  label='Modelo'
                  size='small'
                  fullWidth
                  value={info?.getDetalheStatus?.[0]?.modelo || ''}
                  name='modelo'
                  component={Text}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3} xl={3}>
                <Field
                  variant='outlined'
                  label='Serial'
                  size='small'
                  fullWidth
                  value={info?.getDetalheStatus?.[0]?.serial || ''}
                  name='serial'
                  component={Text}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} xl={12}>
                <Typography variant='h6' fontWeight='bold'>
                  Dados do Placa:
                </Typography>
              </Grid>
              <Grid item xs={12} md={3} xl={3}>
                <Field
                  variant='outlined'
                  label='Serial In'
                  size='small'
                  fullWidth
                  value={info?.getSerialInSerialOut?.[0]?.Serial_Out || ''}
                  name='modelo'
                  component={Text}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3} xl={3}>
                <Field
                  variant='outlined'
                  label='Serial Out'
                  size='small'
                  fullWidth
                  value={info?.getSerialInSerialOut?.[0]?.Serial_in || ''}
                  name='serial'
                  component={Text}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} xl={12}>
                <Table
                  name='Table'
                  maxHeight
                  headers={columns_status}
                  emptyMessage='Nenhum registro encontrado.'
                  disableNumeration
                >
                  {info?.getLogOsStatus?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item?.tipoStatus}</TableCell>
                      <TableCell>{item?.descricao}</TableCell>
                      <TableCell>{item?.dtCadastro}</TableCell>
                      <TableCell>{item?.obs}</TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} xl={12}>
                <Typography variant='h6' fontWeight='bold'>
                  CMP/HF:
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <Table
                  name='Table'
                  maxHeight
                  headers={columns_CMP_HF}
                  emptyMessage='Nenhum registro encontrado.'
                  disableNumeration
                >
                  {info?.getDetalheCmpHf?.length === 0 ? (
                    <TableRow key='1'>
                      <TableCell>----</TableCell>
                      <TableCell>----</TableCell>
                      <TableCell>----</TableCell>
                      <TableCell>----</TableCell>
                    </TableRow>
                  ) : (
                    info?.getDetalheCmpHf?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item?.tipoCMP}</TableCell>
                        <TableCell>{item?.dados}</TableCell>
                        <TableCell>{item?.dtEntrada}</TableCell>
                        <TableCell>{item?.dtLiberado}</TableCell>
                      </TableRow>
                    ))
                  )}
                </Table>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} xl={12}>
                <Typography variant='h6' fontWeight='bold'>
                  Dados do Cliente:
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <Table
                  name='Table'
                  maxHeight
                  headers={columns_dados_cliente}
                  emptyMessage='Nenhum registro encontrado.'
                  disableNumeration
                >
                  <TableRow key={2}>
                    <TableCell>{info?.getOsMultimarcas?.[0]?.CLIPARCEIRONOME}</TableCell>
                    <TableCell>{info?.getOsMultimarcas?.[0]?.CLIPARCEIROCGC}</TableCell>
                    <TableCell>{info?.getOsMultimarcas?.[0]?.CLIPARCEIROENDERECO}</TableCell>
                    <TableCell>{info?.getOsMultimarcas?.[0]?.CLIPARCEIROnumEnd}</TableCell>
                    <TableCell>{info?.getOsMultimarcas?.[0]?.CLIPARCEIROBAIRRO}</TableCell>
                    <TableCell>{info?.getOsMultimarcas?.[0]?.CLIPARCEIROCIDADE}</TableCell>
                    <TableCell>{info?.getOsMultimarcas?.[0]?.CLIPARCEIROESTADO}</TableCell>
                    <TableCell>{info?.getOsMultimarcas?.[0]?.CLIPARCEIROCEP}</TableCell>
                  </TableRow>
                </Table>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} xl={12}>
                <Typography variant='h6' fontWeight='bold'>
                  Defeitos:
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} xl={6}>
                <Field
                  variant='outlined'
                  label='Defeito reclamado pelo cliente'
                  size='small'
                  fullWidth
                  value={info?.getDetalheStatus?.[0]?.defeitoReclamdo || '-----'}
                  name='reclamado'
                  component={Text}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6} xl={6}>
                <Field
                  variant='outlined'
                  label='Defeito encontrado pelo tecnico'
                  size='small'
                  fullWidth
                  value={info?.getDetalheStatus?.[0]?.defeitoConstatado || '-----'}
                  name='encontrado'
                  component={Text}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} xl={12}>
                <Typography variant='h6' fontWeight='bold'>
                  Detalhes laudo:
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <Table
                  name='Table'
                  maxHeight
                  headers={columns_detalhes_laudo}
                  emptyMessage='Nenhum registro encontrado.'
                  disableNumeration
                >
                  <TableRow key={2}>
                    <TableCell>{info?.getDetalheStatus?.[0]?.tipoLaudo || 'N/A'}</TableCell>
                    <TableCell>{info?.getDetalheStatus?.[0]?.descLaudo || 'N/A'}</TableCell>
                    <TableCell>{info?.getCategoriaPnOs?.[0]?.categorias || 'N/A'}</TableCell>
                  </TableRow>
                </Table>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} xl={12}>
                <Typography variant='h6' fontWeight='bold'>
                  Estado de conservação:
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} xl={6}>
                <Field
                  variant='outlined'
                  label='Estado de conservação dos acessórios enviados'
                  size='small'
                  fullWidth
                  value={info?.vinculo}
                  name='reclamado'
                  component={Text}
                  InputLabelProps={{ shrink: true }}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6} xl={6}>
                <Field
                  variant='outlined'
                  label='Estado de conservação do termina'
                  size='small'
                  fullWidth
                  value={info?.vinculoterminal}
                  name='encontrado'
                  component={Text}
                  InputLabelProps={{ shrink: true }}
                  disabled
                />
              </Grid>
            </Grid>
          </Box>
        </Form>
      </Formik>
    );
  };

  return (
    <>
      <PageTitle>Relatório Status OS</PageTitle>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          type_info: 'numeroOs',
          value: '',
        }}
      >
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} xl={3}>
              <Field
                size='small'
                fullWidth
                name='type_info'
                variant='outlined'
                label='Tipo'
                options={type}
                component={Select}
                loading={loading}
              />
            </Grid>
            <Grid item xs={12} md={3} xl={3}>
              <Field
                variant='outlined'
                label=''
                size='small'
                fullWidth
                name='value'
                component={Text}
                loading={loading}
              ></Field>
            </Grid>
            <Grid item xs={12} md={3} xl={3}>
              <Button type='submit' fullWidth loading={loading}>
                Consultar
              </Button>
            </Grid>
          </Grid>
          <Box my={3}>
            <Grid container spacing={2}>
              <Grid xs={12} md={12} xl={12}>
                <Table
                  name='Table'
                  maxHeight
                  headers={columns}
                  emptyMessage='Nenhum registro encontrado.'
                  disableNumeration
                >
                  <TableRow key={roleData?.numeroOs}>
                    <TableCell>{roleData?.numeroOs}</TableCell>
                    <TableCell>{roleData?.serial}</TableCell>
                    <TableCell>
                      {roleData?.proclogreversa === '1'
                        ? roleData?.CLIFINALNOME
                        : roleData?.CLIPARCEIRONOME}
                    </TableCell>
                    <TableCell>{roleData?.status}</TableCell>
                    <TableCell>{roleData?.dtCadastro}</TableCell>
                    <TableCell>
                      {roleData.length === 0 ? (
                        <></>
                      ) : (
                        <IconButton color='primary' onClick={() => hanldeGetAllInfo(roleData)}>
                          <ZoomInIcon />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell>
                      {roleData.length === 0 ? (
                        <></>
                      ) : (
                        <IconButton
                          color='primary'
                          disabled={buttonLaudo}
                          onClick={() => hanldeGetDownloadLaudo(roleData)}
                        >
                          <DescriptionIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                </Table>
              </Grid>
            </Grid>
          </Box>
        </Form>
      </Formik>
      {showInfo ? handleDetail() : <></>}
    </>
  );
};
