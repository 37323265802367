import React from 'react';
import { useRouteMatch } from 'react-router';

// import { FreezingReport } from 'pages/Reports/FreezingReport';
import { AgingReport } from 'pages/Reports/AgingReport';
import { AgingWithPartsReport } from 'pages/Reports/AgingWithPartsReport';
import { ClosingNF1 } from 'pages/Reports/ClosingNF1Report';
import { FreezingReport } from 'pages/Reports/FreezingReport';
import { IncontrolReport } from 'pages/Reports/IncontrolReport';
// import { InOutReport } from 'pages/Reports/InOutReport';
import { InvoicesReport } from 'pages/Reports/InvoicesReport';
import { MinuteReport } from 'pages/Reports/MinuteReport';
import { PartsConsumptionReport } from 'pages/Reports/PartsConsumptionReport';
import { ProductionCleaning } from 'pages/Reports/ProductionCleaningReport';
import { RemoveBlackList } from 'pages/Reports/RemoveBlackList';
import { SalesReport } from 'pages/Reports/SalesReport';
import { SerialBaseQuery } from 'pages/Reports/SerialBaseQueryReport';
import { SerialbyPartnumberReport } from 'pages/Reports/SerialbyPartnumberReport';
import { StatusOs } from 'pages/Reports/StatusOsReport';
import { StockBufferReport } from 'pages/Reports/StockBufferReport';
import { TechnicalStock } from 'pages/Reports/TechnicalStock';
import { ValueTechnicalStock } from 'pages/Reports/ValueTechnicalStockReport';

import { Route } from '../Route';

export const ReportRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${path}/report-freezing`} component={FreezingReport} />
      <Route isPrivate path={`${path}/report-invoicesReport`} component={InvoicesReport} />
      <Route isPrivate path={`${path}/report-listMinuteReport`} component={MinuteReport} />
      <Route isPrivate path={`${path}/report-listSalesReport`} component={SalesReport} />
      <Route isPrivate path={`${path}/report-stock-buffer`} component={StockBufferReport} />
      <Route isPrivate path={`${path}/serial-partnumber`} component={SerialbyPartnumberReport} />
      <Route isPrivate path={`${path}/technical-stock`} component={TechnicalStock} />
      <Route isPrivate path={`${path}/aging-report`} component={AgingReport} />
      {/* <Route isPrivate path={`${path}/in-out`} component={InOutReport} /> */}
      <Route isPrivate path={`${path}/aging-with-parts`} component={AgingWithPartsReport} />
      <Route isPrivate path={`${path}/parts-consumption`} component={PartsConsumptionReport} />
      <Route isPrivate path={`${path}/remove-black-list`} component={RemoveBlackList} />
      <Route isPrivate path={`${path}/incontrol-report`} component={IncontrolReport} />
      <Route isPrivate path={`${path}/serial-base-query`} component={SerialBaseQuery} />
      <Route isPrivate path={`${path}/closing-by-nf1`} component={ClosingNF1} />
      <Route isPrivate path={`${path}/production-cleaning`} component={ProductionCleaning} />
      <Route isPrivate path={`${path}/value-technical-stock`} component={ValueTechnicalStock} />
      <Route isPrivate path={`${path}/status-os`} component={StatusOs} />
    </>
  );
};
