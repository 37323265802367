/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';

import { Table, Button } from 'components';
import { AuthContext } from 'hooks/useAuth';
import { PageTitle } from 'pages/Layout/PageTitle';
import { useContextSelector } from 'use-context-selector';

import { conferenceService } from '../../../../services/conference';
import { AutomaticConference } from './Dialog/AutomaticConferenceDialog';
import { ConferenceDialog } from './Dialog/ConferenceDialog';
import { SignalingDialog } from './Dialog/SignalingDialog';

// Definição das colunas para a tabela principal
const columns = [
  { label: 'Nota' },
  { label: 'CNPJ' },
  { label: 'ClienteOPV' },
  { label: 'Processo' },
  { label: 'Data Doca' },
  { label: 'qtdNF' },
  { label: 'Status' },
  { label: 'Objeto' },
  { label: 'Sinalização' },
  { label: 'Conferência' },
];

export const ConferenceBeeping_new = () => {
  const user = useContextSelector(AuthContext, (context) => context.user);
  const [isModalOpenSignaling, setIsModalOpenSignaling] = useState(false);
  const [isModalOpenConference, setIsModalOpenConference] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterText, setFilterText] = useState('');
  const [selectedNote, setSelectedNote] = useState({});
  const [openDialogAutomaticConference, setOpenDialogAutomaticConference] = useState(false);

  const handleGetDataTable = async () => {
    setTableData([]);
    try {
      const { data } = await conferenceService.getDataTable();
      // Formatando a data da tabela
      const formattedData = data.map((item) => ({
        ...item,
        Data_Doca: formatDate(item.Data_Doca),
      }));
      setTableData(formattedData);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  };

  const handleFilterTextChange = (event) => {
    setFilterText(event.target.value); // Atualiza o texto de filtro com o valor do campo de entrada
    setPage(0); // Resetar a página ao alterar o filtro
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('pt-BR'); // Formatando para o padrão dd/mm/aaaa
  };

  const filteredData = filterText
    ? tableData.filter(
      (item) =>
        item.Nota.toLowerCase().includes(filterText.toLowerCase()) ||
        item.ClienteOPV.toLowerCase().includes(filterText.toLowerCase()) ||
        item.CNPJ.toLowerCase().includes(filterText.toLowerCase()) ||
        item.Data_Doca.toLowerCase().includes(filterText.toLowerCase()) ||
        item.Status.toLowerCase().includes(filterText.toLowerCase()) ||
        item.Processo.toLowerCase().includes(filterText.toLowerCase()),
    )
    : tableData;

  const currentPageData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleOpenModalSignaling = (note) => {
    setSelectedNote(note);
    setIsModalOpenSignaling(true);
  };

  const handleCloseModal = () => {
    handleGetDataTable();
    setIsModalOpenSignaling(false);
    setIsModalOpenConference(false);
  }

  const handleOpenModalConference = (note) => {
    setSelectedNote(note);
    setIsModalOpenConference(true);
  };

  const isModalOpenAutomaticConference = () => {
    setOpenDialogAutomaticConference(true);
  }

  const handleCloseModalAutomaticConference = () => {
    setOpenDialogAutomaticConference(false);
  }

  useEffect(() => {
    handleGetDataTable();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <SignalingDialog
        isModalOpenSignaling={isModalOpenSignaling}
        handleCloseModal={handleCloseModal}
        selectedNote={selectedNote}
        user={user}
      />
      <ConferenceDialog
        isModalOpenConference={isModalOpenConference}
        handleCloseModal={handleCloseModal}
        selectedNote={selectedNote}
        user={user}
      />
      <AutomaticConference
        isModalOpenAutomaticConference={openDialogAutomaticConference}
        handleCloseModalAutomaticConference={handleCloseModalAutomaticConference}
        user={user}
      />
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6} md={6}>
            <PageTitle>Conferencia por Bipagem</PageTitle>
          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <TextField
              size='small'
              fullWidth
              variant='outlined'
              value={filterText}
              onChange={handleFilterTextChange}
              label='Pesquisar'
            />
          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <Button
              fullWidth
              color='secondary'
              onClick={isModalOpenAutomaticConference}
            >
              conferência automatica
            </Button>
          </Grid>
          <Grid item xs={12} lg={12} md={12}>
            <Table name='Table' headers={columns} maxHeight disableNumeration>
              {currentPageData.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.Nota}</TableCell>
                  <TableCell>{item.CNPJ}</TableCell>
                  <TableCell>{item.ClienteOPV}</TableCell>
                  <TableCell>{item.Processo}</TableCell>
                  <TableCell>{item.Data_Doca}</TableCell>
                  <TableCell>{item.qtdNF}</TableCell>
                  <TableCell>{item.Status}</TableCell>
                  <TableCell>{item.Objetivo}</TableCell>
                  <TableCell>
                    <Grid item xs={12} lg={10} md={10}>
                      {item.sinalizada ? (
                        <Button fullWidth onClick={() => handleOpenModalSignaling(item)}>
                          Sinalização OK
                        </Button>
                      ) : (
                        <Button fullWidth onClick={() => handleOpenModalSignaling(item)}>
                          Vincular Sinalização
                        </Button>
                      )}
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid item xs={12} lg={10} md={10}>
                      <Button
                        disabled={item.sinalizada === false}
                        fullWidth
                        color='secondary'
                        onClick={() => { handleOpenModalConference(item) }}
                      >
                        Iniciar Conferência
                      </Button>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component='div'
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
