import React, { useState } from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import EditIcon from '@mui/icons-material/Edit';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { Button, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';

import { receivementService } from '../../../../services/receivement';
import { DialogCorrigir } from './Dialog/DialogCorrigir';
import { DialogEdit } from './Dialog/DialogEdit';

const columnsWhenValueIsZero = [
  { label: 'NF' },
  { label: 'Data Cadastro' },
  { label: 'Qtd Total' },
  { label: 'Tipo Processo' },
  { label: 'Cliente OPV' },
  { label: 'Parceiro' },
  { label: 'Restrição' },
  { label: 'Objeto' },
  { label: 'NF INGENICO' },
  { label: 'Editar' },
];

const columnsWhenValueIsOne = [
  { label: 'NF' },
  { label: 'Data Cadastro' },
  { label: 'Qtd Total' },
  { label: 'Tipo Processo' },
  { label: 'Cliente OPV' },
  { label: 'Parceiro' },
  { label: 'Restrição' },
  { label: 'Objeto' },
  { label: 'NF INGENICO' },
  { label: 'Editar' },
  { label: 'Corrigir' },
];

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

export const CorrectionNF = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [infoNF, setInfoNF] = useState({ result: [], resultExistsNF: [], qtd: Number });
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [openDialogCorrigir, setOpenDialogCorrigir] = useState(false);

  const handleGetNF = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await receivementService.getNF({ nf: values.input_nf });
      setInfoNF({
        result: data.result,
        resultExistsNF: data.resultExistsNF,
        qtd: data.qtd,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleOpenDialogEdit = (item) => {
    setOpenDialogEdit(true);
    setSelectedRowData(item);
  };

  const handleCloseDialogEdit = () => {
    setOpenDialogEdit(false);
  };

  const handleOpenDialogCorrigir = (item) => {
    setOpenDialogCorrigir(true);
    setSelectedRowData(item);
  };

  const handleCloseDialogCorrigir = () => {
    setOpenDialogCorrigir(false);
  };

  return (
    <>
      <DialogCorrigir
        handleCloseDialogCorrigir={handleCloseDialogCorrigir}
        openDialogCorrigir={openDialogCorrigir}
        selectedRowData={selectedRowData}
      />
      <DialogEdit
        handleCloseDialogEdit={handleCloseDialogEdit}
        openDialogEdit={openDialogEdit}
        selectedRowData={selectedRowData}
        infoNF={infoNF}
      />
      <PageTitle>Correção de NF</PageTitle>

      <Formik initialValues={{ input_nf: '' }} onSubmit={handleGetNF}>
        {(props) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} md={2} lg={2}>
                <Field
                  size='small'
                  loading={isLoading}
                  name='input_nf'
                  variant='outlined'
                  fullWidth
                  label='NF a ser corrigida'
                  component={Text}
                />
              </Grid>
              <Grid item xs={3} md={3} lg={3}>
                <Button fullWidth type='submit' loading={isLoading}>
                  Pesquisar
                </Button>
              </Grid>
            </Grid>
            <Box mt={2}>
              <Table
                name='Table'
                headers={infoNF.qtd === 0 ? columnsWhenValueIsZero : columnsWhenValueIsOne}
                maxHeight
                disableNumeration
              >
                {infoNF.result.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.numNF}</TableCell>
                    <TableCell>{formatDate(item.dtCadastro)}</TableCell>
                    <TableCell>{item.qtdTotal}</TableCell>
                    <TableCell>{item.tipoProcesso}</TableCell>
                    <TableCell>{item.clienteOpv}</TableCell>
                    <TableCell>{item.NomeParceiro}</TableCell>
                    <TableCell>{item.Restricao}</TableCell>
                    <TableCell>{item.Objeto}</TableCell>
                    <TableCell>
                      {infoNF.resultExistsNF[index] && infoNF.resultExistsNF[index][0]
                        ? infoNF.resultExistsNF[index][0].NF
                        : '----'}
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => handleOpenDialogEdit(item)}
                        disabled={
                          infoNF.resultExistsNF[index] &&
                          infoNF.resultExistsNF[index][0] &&
                          infoNF.result[0].idTipoStatus != '7'
                            ? false
                            : true
                        }
                      >
                        <EditIcon />
                      </Button>
                    </TableCell>
                    {infoNF.qtd === 1 ? (
                      <TableCell>
                        <Button onClick={() => handleOpenDialogCorrigir(item)}>Corrigir</Button>
                      </TableCell>
                    ) : (
                      <></>
                    )}
                  </TableRow>
                ))}
              </Table>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
